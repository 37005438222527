(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('pollingService', pollingService);

    pollingService.$inject = [

    ];

    function pollingService(

    ) {

        const startPolling = (intervalMs, callback, doImmediate = false) => {
            if (doImmediate){
                callback();
            }
            const timer = setInterval(callback, intervalMs);
            return timer;
        };

        const stopPolling = (timer) => {
            clearInterval(timer);
        };

        const pollingService = {
            startPolling,
            stopPolling,
        };
        
        return pollingService;

    }
})();
