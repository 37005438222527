(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('OrdersController', ordersController);

    ordersController.$inject = [
        '$q', '$timeout', '$state', '$rootScope', '$scope', '$kWindow', '$log', 'networkService', 'ordersService', 'cacheService',
        'orderStatusTransitions', 'routeStopsService', 'routeStopUpdateChecker', 'expirationService', 'logService', 'settingsService',
        'bluetoothService', 'receiptTemplateService', 'lifecycleService', 'appUtils', 'translateService'
    ];

    function ordersController(
        $q, $timeout, $state, $rootScope, $scope, $kWindow, $log, networkService, ordersService, cacheService,
        orderStatusTransitions, routeStopsService, routeStopUpdateChecker, expirationService, logService, settingsService,
        bluetoothService, receiptTemplateService, lifecycleService, appUtils, translateService
    ) {
        $scope.orders = [];
        $scope.loadStatus = '';

        $scope.canDelete = canDelete;
        $scope.isVisible = isVisible;
        $scope.orderClicked = orderClicked;
        $scope.needsAdjust = false;
        $scope.translate = translateService.translate;

        if (navigator.userAgent.search("SamsungBrowser") >= 0) {
            //Samsung browser is not supported, we recommend you to use chrome, otherwise the application might not work correctly
            var samsungBrowserNotSupportedMsg = translateService.translate('orders.samsungBrowserErrorMessage');
            alert(samsungBrowserNotSupportedMsg);
        }

        initController();

        function initController() {
            lifecycleService.stopOrderValidation();

            $q.all([
                settingsService.getEnableWeight(),
                settingsService.getBotekSettings(),
                settingsService.getPrinterEnabled()
            ]).then(([enableWeight, botekSettings, printerEnabled]) => {
                if (enableWeight && !botekSettings.enableBotekHyperionByLocationTime) {
                    bluetoothService.attachBotekWeightUnitDevice();
                }
                if (printerEnabled) {
                    receiptTemplateService.getReceiptTemplate(true);
                    return bluetoothService.attachZQ510Printer();
                }
            });

            loadOrders();
            getInitialZoom();
            
            $scope.$on('refreshOrders', async function () {
                $rootScope.$broadcast('showBusyIndicator');
                const isAppVersionUpToDate = await appUtils.isAppVersionUpToDate();
                if (!isAppVersionUpToDate) {
                    appUtils.logout();
                    $rootScope.$broadcast('hideBusyIndicator');
                    return;
                }
                loadOrders();
            });
        }

        function openSetDriverModal() {
            var deferred = $q.defer();

            settingsService.getDriverList().then((driverList) => {
                var activeDrivers = _.filter(driverList, 'active');
                if (driverList && driverList.length > 0 && activeDrivers.length) {
                    var driverListWindow = $kWindow.open({
                        options: {
                            width: 400,
                            height: 500,
                            modal: true,
                            title: translateService.translate('orders.selectDriver'),
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            close: function () {
                                $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                            }
                        },
                        templateUrl: 'app/orders/modals/set-driver-modal-view.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'SetDriverModalController',
                        resolve: {
                            driverList: () => activeDrivers
                        }
                    });

                    driverListWindow.result
                        .then(data => {
                            deferred.resolve();
                        })
                        .finally(() => {
                            deferred.reject();
                        });
                } else {
                    deferred.resolve();
                }
            });
            return deferred.promise;
        }

        function getInitialZoom() {
            var deferred = $q.defer();

            cacheService.get('cachedZoomLevel').then(function (cachedZoomLevel) {
                $rootScope.cachedZoomLevel = cachedZoomLevel;
                deferred.resolve();
            }, function (err) {
                deferred.resolve();
            });

            return deferred.promise;
        }

        function canDelete(order) {
            return order.isRouteCached && isVisible(order) && new Date(order.orderDate) < moment().startOf('day');
        }

        function isVisible(order) {
            return order.transitionId !== 2 && order.transitionId !== 3;
        }

        function navigateToRouteStopList(order) {
            openSetDriverModal().then(() => {
                $state.go('main.routeStops',
                    {
                        orderId: order.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: 'uncompleted'
                    });
            }).catch(() => {
                alert(translateService.translate('orders.selectDriverErrorMessage'));
            });
        }

        function orderClicked(order) {
            if (!$rootScope.orderDeleteMode || !canDelete(order)) {
                if (order.isRouteCached) {
                    navigateToRouteStopList(order);
                } else {
                    networkService.isOnline().then(function (isOnline) {
                        if (isOnline)
                            navigateToRouteStopList(order);
                        else
                            alert(translateService.translate('orders.checkOutOrderErrorMessage'));
                    });
                }
            } else {
                if (confirm(translateService.translate('orders.confirmationMessage'))) {
                    $rootScope.$broadcast('showBusyIndicator');
                    ordersService.updateOrderStatus(order.orderId, orderStatusTransitions.delete)
                        .then(function () {
                            return routeStopUpdateChecker.scheduleUpdates(order.orderId);
                        })
                        .then(function () {
                            return routeStopsService.queueRouteDelete(order.orderId);
                        })
                        .then(function () {
                            $log.warn('order deleted by user' + order.orderId);
                            return ordersService.queueOrderDelete(order.orderId);
                        })
                        .then(loadOrders)
                        .finally(function () {
                            $rootScope.orderDeleteMode = false;
                            $rootScope.$broadcast('hideBusyIndicator');
                        });
                }
            }
        }

        function adjustColumnSizeToContent() {
            var maxFractionLen = 0;
            const resizeTreshold = 30;
            _.forEach($scope.orders, function (order) {
                var len = order.fractions.length;
                if (maxFractionLen < len) {
                    maxFractionLen = len;
                }
            });
            if (maxFractionLen > resizeTreshold) {
                return true;
            }
            return false;
        }

        //Private functions
        function hideOldOrdersForUndergroundUsers(orders) {
            if ($rootScope.userType === 'underground') {
                return _.reject(orders, o => moment(o.orderDate, "YYYY-MM-DDTHH:mm:ss") < moment().add(-1, 'day'));
            }
            return orders;
        }

        function loadOrders() {
            var deferred = $q.defer();

            $rootScope.$broadcast('showBusyIndicator');
            var currentOrders = [];
            expirationService.deleteExpiredOrders()
                .catch(function () {/* ignore errors */ })
                .then(function () {
                    return ordersService.getOrders(true);
                })
                .then(function (orders) {
                    currentOrders = orders;
                    $rootScope.$broadcast('ordersLoaded');

                    var actualOrders = hideOldOrdersForUndergroundUsers(orders);
                    $scope.orders = _.orderBy(actualOrders, ['orderDate', 'orderType', 'routeName'], ['desc', 'asc', 'asc']);
                    $scope.loadStatus = 'loaded';

                    if (_.some($scope.orders, ['isRouteCached', false])) {
                        cacheService.set('orderDetailTypesWndWasOpened', 'false');
                    }

                    $rootScope.$broadcast('canDeleteOrder', _.some($scope.orders, canDelete));
                    $scope.needsAdjust = adjustColumnSizeToContent();
                    deferred.resolve();
                })
                .then(function () {
                    return expirationService.deleteOrphanedReportedRouteStops(currentOrders);
                })
                .catch(function () {
                    $scope.loadStatus = 'failed';
                    deferred.reject();
                })
                .finally(function () {
                    $rootScope.$broadcast('hideBusyIndicator');
                    return expirationService.uploadOrphanedRoutes();
                })
                .finally(function () {
                    expirationService.retryUploadingMarkedForUploads();
                });

            // temporary dependency
            logService.deleteOldLogs();

            return deferred.promise;
        }
    }
})();
