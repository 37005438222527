(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('botekPanel', botekPanel);

    botekPanel.$inject = [
        "$timeout",
        "$rootScope",
        "$q",
        "commonUtil",
    ];

    function botekPanel(
        $timeout,
        $rootScope,
        $q,
        commonUtil,
    ) {

        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/diganostics/botek-panel.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {

            scope.sendRequest = sendRequest;
            scope.copyToClipboard = copyToClipboard;
            scope.downloadAsTxt = downloadAsTxt;
            scope.response = null;

            $timeout(250).then(initModal);

            function initModal() {

            }

            function sendRequest(event) {
                event.preventDefault();
                let deferred = $q.defer();
                $rootScope.$broadcast('showBusyIndicator');
                const method = document.getElementById('methodInput');
                const baseUrl = document.getElementById('baseUrlInput');
                const urlRoute = document.getElementById('urlRouteInput');

                if (!method || !baseUrl || !urlRoute) {
                    deferred.reject();
                    return deferred.promise;
                }
                const url = baseUrl.value + urlRoute.value;

                if (!commonUtil.isValidHttpUrl(url)) {
                    deferred.reject();
                    return deferred.promise;
                }

                // if (Object.keys(fakeResponse(url)).length){
                //     const response = fakeResponse(url);
                //     scope.response = response;
                //     deferred.resolve(response);
                //     $rootScope.$broadcast('hideBusyIndicator');
                //     return deferred.promise;
                // }

                fetch(url, {
                    method: method.value,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    scope.responseStatus = response.status;
                    return response.json()
                })
                .then(response => {
                    scope.response = response;
                    deferred.resolve(response);
                })
                .catch(error => {
                    scope.response = error;
                    deferred.reject(error);
                })
                .finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator');
                });

                return deferred.promise;

            }

            function fakeResponse(url){
                let response = {};

                const isTxtFileRegex = /http:\/\/localhost:50580\/collection\/[a-zA-Z]+\.txt/i;

                if (isTxtFileRegex.test(url)){
                    response.data = { "D3EX2471.txt" : { "id" : "AAAAAA2471" , "weight" : (Math.round(Math.random() * 150)/100).toString() , "date" : "2022-05-02" , "time" : "16:10:18" , "errorcode" : "" , "errortext" : "" , "modulename" : "B6000L" , "modulenr" : "1" , "gpslat" : "47.2159912" , "gpslong" : "16.6118580" , "devnr" : "0 " } }
                    return response;   
                } else if (url === 'http://localhost:50580/collection/'){
                    response.data = [  { "D3EX2471.txt" : { "id" : "AAAAAA2471" , "weight" : (Math.round(Math.random() * 150)/100).toString() , "date" : "2022-05-02" , "time" : "16:10:18" , "errorcode" : "" , "errortext" : "" , "modulename" : "B6000L" , "modulenr" : "1" , "gpslat" : "47.2159912" , "gpslong" : "16.6118580" , "devnr" : "0 " } } , { "D3EX2472.txt" : { "id" : "AAAAAA2472" , "weight" : "226,0" , "date" : "2022-04-29" , "time" : "08:22:21" , "errorcode" : "" , "errortext" : "" , "modulename" : "B6000L" , "modulenr" : "1" , "gpslat" : "52.520007" , "gpslong" : "12.404954" , "devnr" : "0 " } } , { "D3EX2473.txt" : { "id" : "AAAAAA2473" , "weight" : "205,0" , "date" : "2022-04-29" , "time" : "08:22:24" , "errorcode" : "" , "errortext" : "" , "modulename" : "B6000L" , "modulenr" : "1" , "gpslat" : "5748.2749" , "gpslong" : "01327.8104" , "devnr" : "0 " } } , { "D3EX2474.txt" : { "id" : "AAAAAA2474" , "weight" : "320,0" , "date" : "2022-04-29" , "time" : "08:22:27" , "errorcode" : "" , "errortext" : "" , "modulename" : "B6000L" , "modulenr" : "1" , "gpslat" : "5748.2749" , "gpslong" : "01327.8104" , "devnr" : "0 " } } ]
                    return response;
                }

                return response;
            }

            function copyToClipboard($event, content) {
                const copyElement = document.createElement("textarea");
                copyElement.style.position = "fixed";
                copyElement.style.opacity = "0";
                copyElement.textContent = decodeURI(JSON.stringify(content));
                const body = document.getElementsByTagName("body")[0];
                body.appendChild(copyElement);
                copyElement.select();
                document.execCommand("copy");
                body.removeChild(copyElement);
                tooltipToggler($event.currentTarget);
            }

            function tooltipToggler(element) {
                const tooltipEl = element.parentElement.querySelector(
                    "span.successfull-operation"
                );
    
                if (!tooltipEl) return;
    
                tooltipEl.classList.add("visible");
                $timeout(2500).then(() => tooltipEl.classList.remove("visible"));
            }

            function downloadAsTxt($event, content) {
                const timeStamp = kendo.toString(new Date(), "yyyyMMdd-hhmmss");
                const name = scope.response.config.url + "-" + timeStamp + ".txt";
                commonUtil.saveFile(name, JSON.stringify(content));
                tooltipToggler($event.currentTarget);
            }

        }
    }
})();
