(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopCard', routeStopCard);

    routeStopCard.$inject = ['$rootScope', '$kWindow', '$window', 'appSettings', 'locationService', 'routeStopsService',
        'dataButtonValidationService', 'settingsService', 'commonUtil', 'botekSensorListener', 'translateService', 'displayProfileType', 'botekService'];

    function routeStopCard($rootScope, $kWindow, $window, appSettings, locationService, routeStopsService,
        dataButtonValidationService, settingsService, commonUtil, botekSensorListener, translateService, displayProfileType, botekService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-card.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            settingsService.getException1ButtonVisible().then(function (exception1ButtonVisible) {
                scope.isDeviation1ButtonVisible = exception1ButtonVisible;
            });

            $rootScope.openBotekModal = openBotekModal;

            $rootScope.isBotekModalOpen = false;
            scope.isServiceUser = $rootScope.userType === 'service';
            scope.displayProfileTypeEnum = displayProfileType;
            scope.translate = translateService.translate;

            scope.showEvents = function (evt, routeStop) {
                preventPropagation(evt);
                var height = Math.min($window.innerHeight, 500);
                height -= 37; // header

                $kWindow.open({
                    options: {
                        modal: true,
                        title: 'Logg',
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        maxHeight: height,
                        width: "90%",
                        position: {
                            top: "15%",
                            left: "5%"
                        },
                        close: function () {
                            $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                        }
                    },
                    templateUrl: 'app/routeStops/modals/container-events-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'containerEventsModalController',
                    resolve: {
                        units: function () {
                            return routeStop.units;
                        }
                    }
                });
            };

            scope.showStatistics = function (evt, unit) {
                preventPropagation(evt);
                var width = Math.min($window.innerWidth - 50, 700);
                var height = Math.min($window.innerHeight, 500);
                height -= 37; // header

                if (unit && unit.containerId) {
                    $kWindow.open({
                        options: {
                            modal: true,
                            title: 'Statistikk',
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            height: height,
                            width: width
                        },
                        templateUrl: 'app/routeStops/modals/container-statistics-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ContainerStatisticsModalController',
                        resolve: {
                            containerId: function () {
                                return unit.containerId;
                            }
                        }
                    });
                }
            };

            scope.dataButtonClick = function (evt, routeStop, dataButton) {
                preventPropagation(evt);

                var windowOptions = {
                    modal: true,
                    title: dataButton.valueNO,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 410,
                    width: 400
                };

                var mobileDetect = new MobileDetect(window.navigator.userAgent);
                if (mobileDetect.is('AndroidOS')) {
                    windowOptions.animation = false;
                }

                //Open data button
                if (dataButton.valueENG === 'Weight') {
                    if ($rootScope.botekSettings.enableBotekHyperionByLocationTime) {
                        openBotekModal(routeStop, dataButton, true);
                    } else if ($rootScope.isWeightUnitBotek) {
                        if (!isiOSDevice()) {
                            botekSensorListener.startListening();
                        }
                        openBotekModal(routeStop, dataButton, false);
                    } else {
                        openUnitLevelDataButtonModal(routeStop, dataButton);
                    }
                }
                else if (scope.distributeExtraBagsOnSelectedContainers && dataButton.valueENG === 'Sekker') {
                    openUnitLevelDataButtonModal(routeStop, dataButton);
                }
                else {
                    openDataButtonModal(windowOptions, routeStop, dataButton);
                }

            };

            scope.addFirstDeviation = function (routeStop, firstDeviation) {
                _.forEach(routeStop.agreementLines, agreementLine => {
                    routeStop.deviations.push({ 'agreementLineId': agreementLine.agreementLineId.toString(), 'deviation': firstDeviation });
                });
                routeStop.successful = 'unsuccessful';
                routeStop.message = 'Nei';

                scope.handleRouteStopCompleted(routeStop);
            };

            scope.openVoltAdjust = function (evt, routeStop) {
                preventPropagation(evt);

                if (scope.voltAdjustUrl) {
                    window.open(scope.voltAdjustUrl, '_blank');
                }
            };

            scope.showInfo = function (evt, routeStop) {
                preventPropagation(evt);

                const orderDataKeysToExclude = appSettings.routeStops.infoModal.orderDataKeysToExclude;
                const orderData = routeStop.orderData.filter(orderData => !orderDataKeysToExclude.includes(orderData.key));
                const modalOptions = {
                    modal: true,
                    title: 'Info',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: '90%',
                    maxWidth: 700,
                    close: function () {
                        $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                    }
                };

                $kWindow.open({
                    options: modalOptions,
                    templateUrl: 'app/routeStops/modals/route-stop-info-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'RouteStopInfoController',
                    resolve: {
                        orderData: function () {
                            return orderData;
                        },
                        routeStop: function () {
                            return routeStop;
                        }
                    }
                });
            };

            scope.showDirections = function (evt, routeStop) {
                preventPropagation(evt);

                locationService.getCurrentPosition().then(function (currentPosition) {
                    var directionLink = routeStop.getDirectionLink(currentPosition);
                    var win = undefined;
                    if (('standalone' in window.navigator) && window.navigator.standalone) {
                        win = window.open(directionLink, '_top');

                    } else {
                        win = window.open(directionLink, '_blank');
                    }
                    win.focus();
                });
            };

            scope.stopError = function (evt, routeStop) {
                preventPropagation(evt);
                if ($rootScope.noButtonRequiresDeviation) {
                    $rootScope.$broadcast('openDeviationWindow', evt, routeStop, true);
                } else {
                    routeStop.successful = 'unsuccessful';
                    routeStop.message = 'Nei';
                    routeStop.isAutoComplete = "False";
                    scope.handleRouteStopCompleted(routeStop);
                }
            };

            scope.stopApproved = function (evt, routeStop) {
                preventPropagation(evt);

                routeStop.isAutoComplete = "False";
                routeStop.successful = 'successful';
                routeStop.message = 'Ja';

                if (routeStop.dataButtons) {
                    dataButtonValidationService.validateDataButtons(routeStop).then(validationResult => {
                        if (!validationResult.hasError) {
                            scope.handleRouteStopCompleted(routeStop);
                        } else {
                            alert(validationResult.errorMessage);
                        }
                    });
                } else {
                    scope.handleRouteStopCompleted(routeStop);
                }
            };

            scope.updateUnitPosiiton = function (routeStop) {

                var unit = routeStop.units[0];
                routeStopsService.updateUnitCoordinates(unit.agreementLines[0], routeStop.system, unit.x, unit.y);
            }

            scope.deleteArticle = function (evt, routeStop, article) {
                evt.stopPropagation();
                evt.preventDefault();
                var index = _.findIndex(routeStop.articles, (a) => a.articleId === article.articleId);
                if (index > -1) {
                    routeStop.articles.splice(index, 1);
                }
                routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
            }

            scope.openAddArticle = function (evt, routeStop) {
                evt.stopPropagation();
                evt.preventDefault();

                scope.addArticleModal = $kWindow.open({
                    options: {
                        modal: true,
                        title: translateService.translate('routeStopCard.addArticle'),
                        movable: false,
                        resizable: false,
                        visible: false,
                        height: '600',
                        width: '600'
                    },
                    templateUrl: 'app/articles/add-article-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'articlesController',
                    resolve: {
                        routeStop: function () {
                            return routeStop;
                        },
                        downloadedArticles: function () {
                            return scope.downloadedArticles;
                        }
                    }
                });

                scope.addArticleModal.result.then(function (routeStop) {
                    if (routeStop) {
                        routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                    }
                });
                scope.addArticleModal = null;
            };

            function isiOSDevice() {
                var platform = navigator.platform;
                return platform === 'iPad' || platform === 'iPhone' || platform === 'iPod' || platform.includes('Mac');
            }

            function openBotekModal(routeStop, dataButton, isApi) {

                // Check if the route stop is a Private property. If so, do not open the weight modal automatically.
                if (isApi && routeStop.wasteType == 'Pr' && routeStop.system == 'H'){                   
                    return;
                }

                $rootScope.isBotekModalOpen = true;
            
                if (
                    isApi &&
                    $rootScope.botekSettings.enableBotekHyperionByLocationTime &&
                    !$rootScope.botekSettings.enableBotekHyperionByRFIDGPS &&
                    !$rootScope.botekSettings.enableBotekHyperionByRFID
                ) {
                    botekService.startPolling();
                }
            
                const botekWindowOptions = {
                    modal: true,
                    title: "Vekt - " + routeStop.description,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 460,
                    width: 400,
                };
            
                const botekWindow = $kWindow.open({
                    options: botekWindowOptions,
                    templateUrl: "app/routeStops/modals/weight-sensor-modal.html",
                    windowTemplateUrl: "app/shared/modal-base.html",
                    controller: isApi
                        ? "WeightApiSensorModalController"
                        : "WeightBluetoothSensorModalController",
                    resolve: {
                        routeStop: () => routeStop,
                        dataButton: () => dataButton,
                    },
                });
            
                botekWindow.result
                    .then((data) => {
                        if (data && data.weight && data.routeStop) {
                            var wButtons = _.filter(
                                routeStop.dataButtons,
                                (db) => db.identity === "Weight"
                            );
                            if (wButtons && wButtons.length > 0) {
                                var weightButton = wButtons[0];
                                weightButton.value = data.weight;
                                routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                                $rootScope.$broadcast("addDebugObject", "BotekResultToCache", routeStop);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        $rootScope.isBotekModalOpen = false;
                        if (!routeStop.wasWeightModalClosed) {
                            const newRouteStop = _.cloneDeep(routeStop);
                            newRouteStop.wasWeightModalClosed = true;
                            routeStopsService.saveLocalRouteStop(scope.orderId, newRouteStop);                            
                            $rootScope.$broadcast("addDebugObject", "BotekResultToCache", routeStop);
                        }
                        if (!isApi) {
                            botekSensorListener.stopListening();
                            $rootScope.isWeightSensorPollingCache = false;
                        } else {
                            botekService.stopPolling();
                            botekService.deleteAllFilesFromServer();
                        }
                        $rootScope.$broadcast("hideBusyIndicator");
                    });
            }            

            function openDataButtonModal(windowOptions, routeStop, dataButton) {

                const windowInstance = $kWindow.open({
                    options: windowOptions,
                    templateUrl: 'app/routeStops/modals/data-button-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'DataButtonModalController',
                    resolve: {
                        dataButton: () => dataButton,
                    }
                });

                windowInstance.result.then(function (result) {
                    if (result && result.action === 'save') {
                        var dataValue = commonUtil.correctDataButtonValueFloatFormat(result.value);
                        dataButton.value = dataValue;
                        routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                    }
                });
            }

            function openUnitLevelDataButtonModal(routeStop, dataButton) {

                const unitLevelDataWindowOptions = {
                    modal: true,
                    title: dataButton.valueNO,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 450,
                    width: 400
                };

                const unitLevelDataWindowInstance = $kWindow.open({
                    options: unitLevelDataWindowOptions,
                    templateUrl: 'app/routeStops/modals/unit-level-data-button-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'UnitLevelDataButtonModalController',
                    resolve: {
                        routeStop: () => routeStop,
                        dataButton: () => dataButton,
                    }
                });

                unitLevelDataWindowInstance.result.then(function (result) {
                    if (!(result?.action === "save" && result?.value?.unitLevelValues?.length)) {
                        return;
                    }

                    let dataValues = "";
                    let sumValues = 0;
                    result.value.unitLevelValues.forEach((unitLevelValue) => {
                        const agreementIds =
                            unitLevelValue.agreementLineIds.split(",");
                        let dataValue = unitLevelValue.value ?? "null";
                        if (unitLevelValue.value) {
                            saveUnitNumbers(
                                result.routeStop,
                                unitLevelValue.agreementLineIds,
                                unitLevelValue.value,
                                dataButton.valueENG.toLowerCase()
                            );
                            dataValue =
                                commonUtil.correctDataButtonValueFloatFormat(
                                    unitLevelValue.value
                                );
                            sumValues += Number(dataValue.replace(",", "."));
                        }
                        agreementIds.forEach((aLnId, i) => {
                            dataValues += `${aLnId}:${
                                i === 0
                                    ? dataValue !== ""
                                        ? dataValue.replace(".", ",")
                                        : "null"
                                    : "null"
                            };`;
                        });
                    });

                    if (dataValues !== ":null;") {
                        const precision = dataValues.includes(",") ? 3 : 0
                        if (result.value.isAnyUnitEdited) {
                            dataButton.values = dataValues;
                        }
                        dataButton.value = sumValues.toFixed(precision).toString().replace('.', ',');

                        routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                    }
                    
                });
            }

            function saveUnitNumbers(routeStop, agreementLineIds, unitLevelValue, dataButtonName) {
                const unit = _.find(routeStop.units, u => u.agreementLines.join(',') === agreementLineIds);
                if (unit) {
                    if (!dataButtonName) return;
                    const unitNumbersKeyname = `${dataButtonName}Numbers`
                    unit[unitNumbersKeyname] = unitLevelValue;
                }
            }

            function preventPropagation(evt) {
                if (evt) {
                    evt.stopPropagation();
                    evt.preventDefault();
                }
            }
        }
    }
})();
