(function () {
    'use strict';

    var pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.config(localForageProviderConfig);

    localForageProviderConfig.$inject = ['$localForageProvider'];

    function localForageProviderConfig($localForageProvider) {
        $localForageProvider.config({ name: 'DTA' });
    }

    pwaPoCApp.config(provideConfig);

    provideConfig.$inject = ['$provide'];

    function provideConfig($provide) {
        $provide.decorator('$log', logDecorator);
        $provide.decorator('$rootScope', rootScopeDecorator);
    }

    logDecorator.$inject = ['$delegate', 'logger'];
    function logDecorator($delegate, logger) {
        return logger($delegate);
    }

    rootScopeDecorator.$inject = ['$delegate', '$log'];
    function rootScopeDecorator($delegate, $log) {
        var _emit = $delegate.$emit;

        $delegate.$emit = function () {
            $log.debug(arguments);
            _emit.apply(this, arguments);
        };

        return $delegate;
    }

    pwaPoCApp.constant('defaultWkid', '32233');

    pwaPoCApp.constant('serviceUrls', {
        authorization: 'api/auth',
        callOrders: 'api/orders/callOrders',
        errorReport: 'api/errorreport',
        healthInfo: 'api/healthInfo',
        ping: 'api/ping',
        orders: 'api/orders',
        orderDetailTypes: 'api/orders/orderdetailtypes',
        articles: 'api/articles',
        receiptTemplate: 'api/receiptTemplate',
        trace: 'api/trace',
        imageUrl: 'https://dtaimageservice.azurewebsites.net/api/files'
    });

    pwaPoCApp.constant('imageServiceAuth', {
        username: 'ftpImages',
        password: 'images2007'
    });

    pwaPoCApp.constant('botekSettings', {
        enableBotekHyperionByLocationTime: false,
        enableBotekHyperionByRFIDGPS: false,
        enableBotekHyperionByRFID: false,
        botekHyperionUrl: 'http://localhost:50580',
        botekHyperionPollingIntervalMs: 5000,
        enableBotekHyperionByLocationTimeTimeFiltering: false,
        botekHyperionByLocationTimeTimeFilterMinutes: 60,
        enableBotekHyperionByLocationTimeLocationFiltering: false,
        botekHyperionByLocationTimeLocationFilterMeters: '500',
    });

    pwaPoCApp.constant('appSettings', {
        defaultLanguage: 'no',
        BLE232: {
            SERVICE_UUIDs: [
                "0003abcd-0000-1000-8000-00805f9b0131",
                "0003abcd-0000-1000-8000-00805f9b0133"
            ],
            NOTIFY: "00031234-0000-1000-8000-00805f9b0130",
            RxData: "00031234-0000-1000-8000-00805f9b0131",
            BLE_CONF: "00031234-0000-1000-8000-00805f9b0132",
            MacID: "00031234-0000-1000-8000-00805f9b0133",
            DeviceName: "00031234-0000-1000-8000-00805f9b0134",
            Reboot: "00031234-0000-1000-8000-00805f9b0135",
            PinID_Setting: "00031234-0000-1000-8000-00805f9b0136"
        },
        ZQ510: {
            BUFFER_SIZE: 20, //TEST
            SERVICE_UUIDs: ["38eb4a80-c570-11e3-9507-0002a5d5c51b"], //PROD
            //SERVICE_UUIDs: [ "0003abcd-0000-1000-8000-00805f9b0131", "0003abcd-0000-1000-8000-00805f9b0133"], //TEST
            FromPrinter: "38eb4a81-c570-11e3-9507-0002a5d5c51b",
            ToPrinter: "38eb4a82-c570-11e3-9507-0002a5d5c51b" //PROD
            //ToPrinter: "00031234-0000-1000-8000-00805f9b0131"//TEST
        },
        traceEnabled: false,
        traceIntervalLength: 60000,
        bluetoothWeightEnabled: true,
        bleNameFilters: [{ name: 'BLE32' }, { name: 'BLE232' }, { name: 'USBLE32' }, { name: 'USBLE232' }, { name: 'USBLE232DEXA' }],
        setDriverPasswordCheckRequired: false,
        botekCheckInterval: 4000,
        errorReportSocketCheckInterval: 10000,
        testMovementSimulatorEnabled: false,
        displayedSequenceNumberCount: 20,
        maxZoomLevelShowingSequence: 12,
        nearbyRouteStopDist: 500,
        nearbyMaxHits: 200,
        callOrdersMinPollingIntervalMs: 20000,
        callOrdersPollingIntervalMs: 60000,
        clearCacheEnabled: true,
        directMessagePollingIntervalMs: 120000,
        filterOptions: [
            {
                id: 0,
                applyMinLength: 1,
                displayName: 'Rekkefølge',
                iconClass: 'fa fa-hashtag',
                type: 'equals',
                property: 'sequence',
                name: 'sequence',
                supportedViews: ['default']
            },
            {
                id: 1,
                applyMinLength: 1,
                displayName: 'Adresse',
                iconClass: 'fa fa-map-pin',
                type: 'contains',
                property: 'description',
                name: 'description',
                supportedViews: ['default', 'service', 'underground']
            },
            {
                id: 2,
                applyMinLength: 1,
                displayName: 'Avstand',
                iconClass: 'fa fa-road',
                type: 'lessThan',
                property: 'distance',
                name: 'distance',
                propertyMapper: function (distance) {
                    return distance / 1000;
                },
                supportedViews: ['default', 'service', 'underground']
            },
            {
                id: 3,
                applyMinLength: 1,
                displayName: 'Fyll %',
                iconClass: 'fa fa-fill',
                type: 'contains',
                property: 'units',
                name: 'fill',
                supportedViews: ['underground']
            },
            {
                id: 4,
                applyMinLength: 1,
                displayName: 'Volt',
                iconClass: 'fas fa-car-battery',
                type: 'lessThan',
                property: 'units',
                name: 'volt',
                propertyMapper: function (units) {
                    return units[0].voltText.replace('V', '').replace(',', '.');
                },
                supportedViews: ['service']
            },
            {
                id: 5,
                applyMinLength: 1,
                displayName: 'Dager',
                iconClass: 'far fa-clock',
                type: 'greaterThan',
                property: 'orderData',
                name: 'days',
                propertyMapper: function (orderData) {
                    return orderData[1].value.split(';')[1];
                },
                supportedViews: ['service', 'underground']
            },
            {
                id: 6,
                applyMinLength: 1,
                displayName: 'ContainerNr',
                iconClass: 'fa fa-hashtag',
                type: 'equals',
                property: 'units',
                name: 'containerId',
                propertyMapper: function (units) {
                    return units[0].containerId;
                },
                supportedViews: ['service', 'underground']
            },
            {
                id: 7,
                applyMinLength: 0,
                displayName: 'Prioritet: I dag',
                iconClass: 'fa fa-chart-line',
                type: 'lessThan',
                property: 'dateWhenFull',
                name: 'dateWhenFullToday',
                propertyMapper: function (dateWhenFull) {
                    if (!dateWhenFull) {
                        return Number.MAX_VALUE;
                    }
                    return moment(dateWhenFull).diff(moment(), 'day');
                },
                supportedViews: ['underground'],
                presetValue: "1"
            },
            {
                id: 8,
                applyMinLength: 0,
                displayName: 'Prioritet: 1-3 dager',
                iconClass: 'fa fa-chart-line',
                type: 'lessThan',
                property: 'dateWhenFull',
                name: 'dateWhenFullIn3Days',
                propertyMapper: function (dateWhenFull) {
                    if (!dateWhenFull) {
                        return Number.MAX_VALUE;
                    }
                    return moment(dateWhenFull).diff(moment(), 'day');
                },
                supportedViews: ['underground'],
                presetValue: "3"
            }
        ],
        sortOptions: [
            { displayName: 'Rekkefølge', value: 'sequence' },
            { displayName: 'Adresse', value: 'description' },
            { displayName: 'Avstand', value: 'distance', default: true }
        ],
        deleteOldLogsIntervalMs: 86400000, // 4 days
        locationExpireMs: 30000,
        getPositionIntervalMs: 1000,
        healthInfoUpdateMs: 60000,
        wrongRouteStopUploadRequestExpireMs: 300000, // 5 mins
        minimumLogLevel: 3, // 0: 'debug', 1: 'extendedInfo', 2: 'info', 3: 'warn', 4: 'error', 5: none
        map: {
            defaultMap: {
                center: {
                    lat: 63.44084,
                    lng: 10.403398,
                    zoom: 19
                },
                tiles: {
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    options: {
                        tileSize: 512,
                        zoomOffset: -1,
                        minZoom: 6,
                        maxZoom: 20
                    }
                }
            },
            esriLayer: {
                center: {
                    lat: 63.44084,
                    lng: 10.403398,
                    zoom: 16
                },
                projection: {
                    code: 'EPSG:32633',
                    proj4def: '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
                    options: {
                        origin: [-2500000.0, 9045984.0],
                        resolutions: [
                            21674.7100160867,
                            10837.35500804335,
                            5418.677504021675,
                            2709.3387520108377,
                            1354.6693760054188,
                            677.3346880027094,
                            338.6673440013547,
                            169.33367200067735,
                            84.66683600033868,
                            42.33341800016934,
                            21.16670900008467,
                            10.583354500042335,
                            5.291677250021167,
                            2.6458386250105836,
                            1.3229193125052918,
                            0.6614596562526459,
                            0.33072982812632296,
                            0.16536491406316148
                        ],
                        tranformation: new L.Transformation(1, 2500000, -1, 9045984)
                    }
                },
                tiles: {
                    url: 'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/GeocacheBasis/MapServer',
                    detectRetina: false,
                    minZoom: 3,
                    maxZoom: 17,
                    opacity: 1
                }
            },
            followMe: true,
            popupEnabled: true,
            labelEnabled: false,
            labelMinZoomLevel: 14,
            maxMarkerCount: 500,
            useEsriLayer: true
        },
        orders: {
            defaultCleanOrderDataOlderThanInDays: 5
        },
        ping: {
            offline: 5000,
            online: 20000
        },
        quotaExceededErrorReportMinIntervalMs: 7200000, // 2 hours
        updateQueueTriggerWaitMs: 4000,
        routeStops: {
            list: {
                accuracyTreshold: 11,
                displayCount: 50,
                displayDistance: 1,
                displayIncrement: 20,
                infiniteScrollDisabled: false,
                showSequenceMaxRouteStopCount: 1000,
                showSequenceMaxLength: 4
            },
            panes: [{ min: '0px', size: '100%' }, {}],
            sliders: {
                defaultDistanceFilter: 20,
                defaultSpeedFilter: 5,
                detectionSlider: {
                    basic: {
                        min: 0,
                        max: 100,
                        step: 5
                    },
                    pips: {
                        rest: 'label',
                        step: 1,
                        suffix: 'm'
                    }
                },
                speedSlider: {
                    basic: {
                        min: 0,
                        max: 10,
                        step: 1
                    },
                    pips: {
                        rest: 'label',
                        step: 1,
                        suffix: 'km/h'
                    }
                }
            },
            splitterClickFirstPaneSize: '50%',
            adjustPositionAccuracyLimit: 20,
            infoModal: {
                orderDataKeysToExclude: ['infoOnKeys', 'latestDeviations']
            }
        }
    });
})();
