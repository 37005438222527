(function () {
    angular.module('PWAPoCApp').controller('WeightApiSensorModalController', weightApiSensorModalController);

    weightApiSensorModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'routeStop',
        'dataButton',
        'botekService',
        'pollingService',
        'commonUtil'];

    function weightApiSensorModalController(
        $rootScope,
        $scope,
        $windowInstance,
        routeStop,
        dataButton,
        botekService,
        pollingService,
        commonUtil) {
       
        $scope.isManualMode = false;
        $scope.numbers = '';
        $scope.weightValues = [];

        const botekWaitingMsg = "Venter på vektdata";
       
        initController();

        function initController() {
            $scope.isManualMode = false;
            $rootScope.$broadcast('showBusyIndicator', botekWaitingMsg);
            const weightValuesPolling = pollingService.startPolling(200, () => {
                if ($rootScope.botekWeightValues?.length) {
                    $rootScope.$broadcast('hideBusyIndicator');
                    pollingService.stopPolling(weightValuesPolling);
                }
            });
        }

        $scope.setValue = function() {
            botekService.stopPolling();
            if (!$scope.isManualMode) {
                botekService.deleteAllFilesFromServer();
            }
            $windowInstance.close({ routeStop: routeStop, weight: $rootScope.botekSumWeight });
        };

        
        $scope.setManual = function () {
            if (!Number($rootScope.botekSumWeight)) {
                $rootScope.$broadcast('hideBusyIndicator');
                initManual();
                $scope.isManualMode = true;
            }
        }

        $scope.OkButtonEnabled = function() {
            return Number($rootScope.botekSumWeight);
        }
        $scope.ManualEnabled = ()=> {
            return !Number($rootScope.botekSumWeight);
        }
       
        //manual input weight
        $scope.numbers = '';
        $scope.addComma = addComma;
        $scope.back = back;
        $scope.save = save;
        $scope.saveEnabled = () => Number($rootScope.botekSumWeight);

        function initManual() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value.toString();
            }
        }

        function addComma() {
            if ($scope.numbers.indexOf(',') === -1) {
                $scope.numbers += ',';
            }
        }

        function back() {
            $scope.isManualMode = false;
            $rootScope.$broadcast('showBusyIndicator', botekWaitingMsg);
        }

        function save() {
            $rootScope.botekSumWeight = commonUtil.correctFloatFormat($scope.numbers);
            $scope.setValue();
        }
    }
})();
