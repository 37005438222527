(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('logger', logger);

    logger.$inject = ['$injector', 'appSettings'];

    function logger($injector, appSettings) {

        var logger = function ($delegate) {
            var minLevel = appSettings.minimumLogLevel;
            return {
                debug: function () {
                    $delegate.debug.apply(null, arguments);
                    if (minLevel < 1 || (minLevel < 2 && isExtendedInfo(arguments[0]))) cacheLog('debug', [].slice.call(arguments));
                },
                error: function () {                    
                    // Prevent infinite loop if an error is thrown while handling an error                
                    try {                                        
                        $delegate.error.apply(null, arguments);
                
                        const errors = Array.from(arguments).map(arg => ({
                            message: arg.message,
                            callStack: arg.stack
                        }));
                                        
                        if (minLevel < 5) cacheLog('error', errors);
                    } catch (e) {
                        console.error("An error occurred while handling another error:", e);
                    }
                },
                info: function () {
                    $delegate.info.apply(null, arguments);
                    if (minLevel < 3) cacheLog('info', [].slice.call(arguments));
                },
                log: function () {
                    $delegate.log.apply(null, arguments);
                    if (minLevel < 3) cacheLog('info', [].slice.call(arguments));
                },
                warn: function () {
                    $delegate.warn.apply(null, arguments);
                    if (minLevel < 4) cacheLog('warn', [].slice.call(arguments));
                }
            }
        };

        return logger;

        function cacheLog(logLevel, message) {           
            var logItem = {
                logLevel: logLevel,
                message: message,
                timestampUtc: moment.utc().format()
            };

            var cacheService = $injector.get('cacheService');
            cacheService.get('_log').then(function(logs) {
                logs = logs || [];

                var oneWeekAgo = moment.utc().subtract(7, 'days');
        
                var errorLogs = logs.filter(function(item) {
                    return item.logLevel === 'error';
                });
        
                var otherLogs = logs.filter(function(item) {
                    return item.logLevel !== 'error';
                });
        
                errorLogs = errorLogs.filter(function(item) {
                    return moment.utc(item.timestampUtc).isAfter(oneWeekAgo);
                });
        
                if (errorLogs.length >= 1000) {
                    errorLogs = errorLogs.slice(errorLogs.length - 999);
                }
        
                var finalLogs = errorLogs.concat(otherLogs);

                finalLogs.sort(function(a, b) {
                    return moment.utc(a.timestampUtc).diff(moment.utc(b.timestampUtc));
                });
        
                finalLogs.push(logItem);
        
                cacheService.set('_log', finalLogs);
            });
        };

        //Private functions
        function isExtendedInfo(log) {
            var excludedEvents = ['[AngularJS - Leaflet]', 'leafletDirectiveMarker', 'leafletDirectiveMap', 'LeafletEventsHelpersFactory', 'kendoRendered', 'kendoWidgetCreated',
                '$viewContentAnimationEnded', '$viewContentLoading', '$viewContentLoaded'];
            var excludedUrls = ['api/ping', 'api/auth', 'api/message/directmessages'];

            var notExtendedInfo = _.some(excludedEvents, function (excludedEvent) {
                    return log && ((_.isArguments(log) && log[0].indexOf(excludedEvent) > -1) || (_.isString(log) && log.indexOf(excludedEvent) > -1));
                }) ||
                _.some(excludedUrls, function (url) {
                    return log && log.url && log.url.indexOf(url) > -1;
                });

            return !notExtendedInfo;
        }

    }
})();