(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('botekService', botekService);

    botekService.$inject = ['$q', '$rootScope', 'locationService', 'routeStopsService', 'pollingService', 'commonUtil'];

    function botekService($q, $rootScope, locationService, routeStopsService, pollingService, commonUtil) {

        
        const botekService = {
            getFiles: getFiles,
            getWeightValues: getWeightValues,
            deleteAllFilesFromServer: deleteAllFilesFromServer,
            startPolling: startPolling,
            startPollingByRFID: startPollingByRFID,
            stopPollingByRFID: stopPollingByRFID,
            stopPolling: stopPolling,
        };

        let polling = null;
        let pollingByRFID = null;
        let lastRouteLineIds = [];
        let alertShown = false;

        return botekService;

        async function startPollingByRFID(routeStopList, orderId) {
            const routeLineIds = routeStopList.map(({ routeLineId }) => routeLineId);
            if (pollingByRFID && !_.isEqual(lastRouteLineIds, routeLineIds)) {
              stopPollingByRFID();
            }
          
            if (pollingByRFID || (!pollingByRFID && _.isEqual(lastRouteLineIds, routeLineIds))) {
              return;
            }
          
            lastRouteLineIds = routeLineIds;
          
            pollingByRFID = pollingService.startPolling($rootScope.botekSettings?.botekHyperionPollingIntervalMs, async () => {
              try {
                const files = await getFiles();
                const routeStopsWithFiles = getRouteStopsWithRFIDFiles(routeStopList, files);
                if (!routeStopsWithFiles.length) return;
                await getMergedRouteStopsWithRFIDFiles(routeStopsWithFiles, orderId);
                deleteFilesWithUpdatedRouteStops(routeStopsWithFiles);
              } catch (error) {
                // Handle error if needed
              }
            }, true);
          };

        function stopPollingByRFID(){
            if (!pollingByRFID) return;
            if (pollingByRFID) {
                pollingService.stopPolling(pollingByRFID);
                pollingByRFID = null;
            }
        }

        function getRouteStopsWithRFIDFiles(routeStopList, files) {
            function getFileByRFID(RFID) {
              return files.find(file => getFileRFID(file) === RFID);
            }
          
            const routeStops = routeStopList.filter(routeStop => {
              const units = routeStop.units?.filter(unit => {
                const RFID = unit.labelId;
                const file = getFileByRFID(RFID);
                return !!file;
              }) || [];
              return units.length > 0;
            });
          
            const routeStopsWithFiles = routeStops.map(routeStop => {
              const RFID = routeStop.units.find(unit => unit.labelId).labelId;
              const file = getFileByRFID(RFID);
              return {
                routeStop: routeStop,
                file: file
              };
            });
          
            return routeStopsWithFiles;
          }
          

        async function getMergedRouteStopsWithRFIDFiles(routeStopsWithFiles, orderId) {
            const routeStops = routeStopsWithFiles.map(async rs => {
                const routeStop = rs.routeStop;
                const file = rs.file;

                // Routestop does not have weight data button
                if (!routeStop.dataButtons?.find(db => db.identity === 'Weight')) return;
        
                // Get updated RFID unit
                const updatedRFIDUnit = getUpdatedRFIDUnit(routeStop, file);
                const RFIDUnitIndex = routeStop.units.findIndex(u => u.labelId === getFileRFID(file));
                routeStop.units[RFIDUnitIndex] = updatedRFIDUnit;
        
                // Get updated weight data button
                const updatedWeightDataButton = getUpdatedWeightDataButton(routeStop, file);
                const weightDataButtonIndex = routeStop.dataButtons.findIndex(db => db.identity === 'Weight');
                routeStop.dataButtons[weightDataButtonIndex] = updatedWeightDataButton;
        
                return routeStopsService.updateRouteStop(orderId, routeStop);
            });
        
            return Promise.all(routeStops);
        }
        
        function getUpdatedRFIDUnit(routeStop, file) {
            const RFIDUnitIndex = routeStop.units.findIndex(u => u.labelId === getFileRFID(file));
            const RFIDUnit = routeStop.units[RFIDUnitIndex];
            return {
                ...RFIDUnit,
                message: 'Ja'
            };
        }
        
        function getUpdatedWeightDataButton(routeStop, file) {
            const RFIDUnit = routeStop.units.find(u => u.labelId === getFileRFID(file));
            const agreementLineId = RFIDUnit.agreementLines[0];
            const weightDataButtonIndex = routeStop.dataButtons.findIndex(db => db.identity === 'Weight');
            const weightDataButton = routeStop.dataButtons[weightDataButtonIndex];
            const currentWeights = getCurrentWeights(routeStop, weightDataButton);
            const newValuesString = getNewValuesString(routeStop, file, agreementLineId, currentWeights);
            const newSumWeightString = calculateNewSumWeightString(newValuesString, agreementLineId);
            return {
                ...weightDataButton,
                values: newValuesString,
                value: newSumWeightString
            };
        }

        function getCurrentWeights(routeStop, weightDataButton) {
            if (!weightDataButton.values) {
                const initWeights = initCurrentWeightArray(routeStop);
                return initWeights;
            }
            else {
                return weightDataButton.values.split(';');
            }
        }

        function initCurrentWeightArray(routeStop) {
            return _.flatten(routeStop.units.map(unit => {
                const agreementLineIds = _.flatten(unit.agreementLines);
                return agreementLineIds.map(al => {
                    return `${al}:null`;
                });
            }));
        }
        
        function getNewValuesString(routeStop, file, agreementLineId, currentWeights) {
            return routeStop.units.map(unit => {
                const agreementLineIds = _.flatten(unit.agreementLines);
        
                return agreementLineIds.map(al => {
                    if (al == agreementLineId) {
                        const weight = getWeightFromFile(file).replace('.', ',');
                        return `${al}:${weight}`;
                    } else {
                        return `${al}:${currentWeights.find(cw => cw.split(':')[0] == al).split(':')[1]}`;
                    }
                }).join(';');
            }).join(';');
        }
        
        function calculateNewSumWeightString(newValuesString, agreementLineId) {
            const sumWeight = newValuesString
            .split(";")
            .reduce((acc, pair) => {
                const [agrLineId, weightStr] = pair.split(":");
                const weight = agrLineId == agreementLineId ? weightStr === "null" ? 0 : Number(weightStr.replace(',', '.')) : 0;
                return acc + weight;
            }, 0);
            return `${sumWeight}`.replace('.', ',');
        }
        

        function startPolling(){
            if (polling) return;
            
            console.log("Polling started");
            deleteAllFilesFromServer().then(() => {
                polling = pollingService.startPolling($rootScope.botekSettings.botekHyperionPollingIntervalMs, function(){
                    getFiles()
                    .then(function(files){
                        filterFiles(files)
                        .then(function(filteredFiles){
                            $rootScope.botekFiles = filteredFiles;
                            const weightValues = getWeightValues(filteredFiles);
                            setWeightValuesToRootScope(weightValues);
                        })
                        .catch(function(error){
    
                        });
                    })
                    .catch(function(error){
                       
                    });
                }, true);
            });            
        }

        function stopPolling(){
            if (!polling) return;
            if (polling) {
                pollingService.stopPolling(polling);
                polling = null;
                console.log("Polling stopped");
            }
        }

        function applyTimeFilter(files, timeFilterMinutes = 0){
            if (!files) return;

            const filteredFiles = files.filter(file => {
                const fileDate = new Date(file[getFileId(file)].date + ' ' + file[getFileId(file)].time);
                const now = new Date();
                const diff = now.getTime() - fileDate.getTime();
                const diffMinutes = Math.floor((diff / 1000) / 60);
                return diffMinutes <= timeFilterMinutes;
            });

            return filteredFiles;
        }

        function applyLocationFilter(files, currentLocation, distanceFilterMeters = 1){
            if (!files || !currentLocation) return;
            if (!files.length) return [];

            const filteredFiles = files.filter(file => {
                const distance = geolib.getDistance({
                    latitude: file[getFileId(file)].gpslat,
                    longitude: file[getFileId(file)].gpslong
                },{
                     latitude: currentLocation.coords.latitude,
                     longitude: currentLocation.coords.longitude
                })
               return distance <= distanceFilterMeters;
            });

            return filteredFiles;
        }

        function getWeightValues(files){
            if (!files) return [];
            const weightValues = files.map(file => {
                const objKeys = Object.keys(file);
                const fileName = objKeys[0];
                const weight = file[fileName].weight;
                return weight;
            });

            return weightValues || [];
        }

        function setWeightValuesToRootScope(weightValues){
            const waitingMsg = "Venter på vektdata";
            if (weightValues.length){
                $rootScope.botekWaitingMsg = null;
                
                const numbers = _.map(weightValues, w=>Number(commonUtil.correctDataButtonValueFloatFormat(w).split(',').join('.'))); //"0,0" -> "0" -> 0
                const sum = _.sum(numbers);
                $rootScope.botekSumWeight = sum.toString();
                $rootScope.botekWeightValues = weightValues;
            } else{
                $rootScope.botekSumWeight = "";
                $rootScope.botekWeightValues = [];
                $rootScope.botekWaitingMsg = waitingMsg;
            }
        }

        function getFileId(file){
            const objKeys = Object.keys(file);
            const fileId = objKeys[0];
            return fileId;
        }

        function getFileRFID(file) {
            const objKeys = Object.keys(file);
            const fileName = objKeys[0];
            const RFID = file[fileName].id;
            return RFID;
        }

        function getWeightFromFile(file) {
            const objKeys = Object.keys(file);
            const fileName = objKeys[0];
            const weight = file[fileName].weight;
            return weight;
        }

        async function getFiles() {
            const botekSettings = $rootScope.botekSettings;
            const baseUrl = botekSettings.botekHyperionUrl;
        
            //Uncomment for test data
            //const fakeData = [{ "D3EX2471.txt": { "id": "666", "weight": (Math.round(Math.random() * 150) / 100).toString(), "date": "2022-05-05", "time": "13:40:18", "errorcode": "", "errortext": "", "modulename": "B6000L", "modulenr": "1", "gpslat": "63.3581", "gpslong": "10.51121", "devnr": "0 " } }, { "D3EX2472.txt": { "id": "777", "weight": "226,0", "date": "2022-04-29", "time": "08:22:21", "errorcode": "", "errortext": "", "modulename": "B6000L", "modulenr": "1", "gpslat": "63.3581", "gpslong": "10.51121", "devnr": "0 " } }, { "D3EX2473.txt": { "id": "AAAAAA2473", "weight": "205,0", "date": "2022-05-05", "time": "13:50:24", "errorcode": "", "errortext": "", "modulename": "B6000L", "modulenr": "1", "gpslat": "5748.2749", "gpslong": "01327.8104", "devnr": "0 " } }, { "D3EX2474.txt": { "id": "AAAAAA2474", "weight": "320,0", "date": "2022-04-29", "time": "08:22:27", "errorcode": "", "errortext": "", "modulename": "B6000L", "modulenr": "1", "gpslat": "5748.2749", "gpslong": "01327.8104", "devnr": "0 " } }];
            //return fakeData;
            //with fetch there's no need for $q.defer()
            try {
                const response = await fetch(baseUrl + '/collection', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                const data = await response.json();
                return data; // Return fake data if the API returns empty response
            } catch (error) {
                console.log(error.message);
                if(!alertShown){
                    alertShown = true;
                    alert(error.message);                  
                }
                deferred.reject(error);
            }
        }
        

        function filterFiles(files, botekSettings = $rootScope.botekSettings){
            const deferred = $q.defer();

            if (!files?.length){
                deferred.resolve([]);
                return deferred.promise;
            }

            let filteredFiles = [];
            let locationFilteredFiles = [];
            let timeFilteredFiles = [];

            //No filtering
            if (!botekSettings.enableBotekHyperionByLocationTimeLocationFiltering && !botekSettings.enableBotekHyperionByLocationTimeTimeFiltering) {
                deferred.resolve(files);
            }

            //Pre set time filtering without promise
            if (botekSettings.enableBotekHyperionByLocationTimeTimeFiltering){
                timeFilteredFiles = applyTimeFilter(files, botekSettings.botekHyperionByLocationTimeTimeFilterMinutes);
            }

            //Only time filtering
            if (!botekSettings.enableBotekHyperionByLocationTimeLocationFiltering && botekSettings.enableBotekHyperionByLocationTimeTimeFiltering) {
                deferred.resolve(timeFilteredFiles);
            }
            //Location filtering involved
            else {
                locationService.getCurrentPosition()
                .then(function(currentLocation){
                    locationFilteredFiles = applyLocationFilter(files, currentLocation, botekSettings.botekHyperionByLocationTimeLocationFilterMeters);
                    //Both filtering
                    if (botekSettings.enableBotekHyperionByLocationTimeLocationFiltering && botekSettings.enableBotekHyperionByLocationTimeTimeFiltering){
                        filteredFiles = timeFilteredFiles.filter(a => locationFilteredFiles.some(b => getFileId(a) === getFileId(b)));
                        deferred.resolve(filteredFiles);
                    }
                    //Only location filtering
                    else{
                        deferred.resolve(locationFilteredFiles)
                    }
                })
                .catch(function(error){
                    deferred.reject(error);
                })
            }
            return deferred.promise;
        }

        function deleteAllFilesFromServer(){
            const deferred = $q.defer();

            getAllFileIdsFromServer()
            .then(function(fileIds){
                const requests = [];

                fileIds.forEach(fileId => {
                    requests.push(deleteFile(fileId));
                });

                $q.all(requests)
                .then(function(response){
                    console.log('Deleted ' + fileIds.length + ' files');
                    $rootScope.botekFiles = [];
                    setWeightValuesToRootScope([]);
                    deferred.resolve(response);
                })
                .catch(function(error){
                    deferred.reject(error);
                });
            });
            
            return deferred.promise;
            
        }

        function deleteFilesWithUpdatedRouteStops(routeStopsWithFiles) {
            const fileIds = routeStopsWithFiles.map(rsFile => getFileId(rsFile.file));
            deleteFileListFromServer(fileIds);
        }

        function deleteFileListFromServer(fileIds){
            const deferred = $q.defer();

            const requests = [];

            fileIds.forEach(fileId => {
                requests.push(deleteFile(fileId));
            });

            $q.all(requests)
            .then(function(response){
                console.log('Deleted ' + fileIds.length + ' files');
                deferred.resolve(response);
            })
            .catch(function(error){
                console.log(error.message);
                deferred.reject(error);
            });
            
            return deferred.promise;
            
        }

        function getAllFileIdsFromServer(){
            const deferred = $q.defer();
            const botekSettings = $rootScope.botekSettings;
            const baseUrl = botekSettings.botekHyperionUrl;
            //Uncomment for test data
            // const fakeData = [  "D3EX2471.txt" , "D3EX2472.txt" , "D3EX2473.txt" , "D3EX2474.txt" ];
            // deferred.resolve(fakeData);

            //with $http there's a problem with CORS, so we use fetch
            fetch(baseUrl + '/collection/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function(response){
                return response.json();
            })
            .then(function(data){
                deferred.resolve(data);
            })
            .catch(function(error){
                console.log(error.message);
                if(!alertShown){
                    alertShown = true;
                    alert(error.message);                  
                }
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function deleteFile(id){
            const deferred = $q.defer();
            const botekSettings = $rootScope.botekSettings;
            const baseUrl = botekSettings.botekHyperionUrl;
            //Uncomment for test data
            // const fakeResponse = `${id} deleted`;
            // deferred.resolve(fakeResponse);
            // return deferred.promise;

            //with $http there's a problem with CORS, so we use fetch
            fetch(baseUrl + '/collection/' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function(response){
                return response.text();
            })
            .then(function(data){
                deferred.resolve(data);
            })
            .catch(function(error){
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
})();
