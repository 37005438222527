(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('commonUtil', commonUtil);

    commonUtil.$inject = ['orderDataDescriptions'];

    function commonUtil(orderDataDescriptions) {
        var service = {
            generateGuid: generateGuid,
            mapDetailDataKey: mapDetailDataKey,
            getOrderDataItemSanitizedValue: getOrderDataItemSanitizedValue,
            stringToBase64: stringToBase64,
            arrayFromString: arrayFromString,
            correctDataButtonValueFloatFormat: correctDataButtonValueFloatFormat,
            convertBooleanStringToBoolean: convertBooleanStringToBoolean,
            hideModal: hideModal,
        };

        return service;

        function generateGuid() {
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
                d += performance.now();
            }
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }

        function mapDetailDataKey(value) {
            var result = value;

            if (value) {
                if (value in orderDataDescriptions) {
                    result = orderDataDescriptions[value];
                } else {
                    var regex = /\[~(.*?)~\]/i;
                    var matches = value.match(regex);
    
                    if (matches && matches.length > 1 && orderDataDescriptions[matches[1]]) {
                        result = orderDataDescriptions[matches[1]];
                    }
                }
            }

            return result;
        }

        function getOrderDataItemSanitizedValue(orderDataItem) {
            if (
                !orderDataItem ||
                orderDataItem.value == undefined ||
                !orderDataItem.description ||
                orderDataItem.value === "[]"
            ) {
                return "";
            }

            if (
                orderDataItem.key === "[agreementLine_LastDate]" ||
                orderDataItem.key === "[agreementLine_NextDate]"
            ) {
                if (orderDataItem.value === "01/01/1900 00:00:00") {
                    return "";
                }

                var time = moment(
                    orderDataItem.value,
                    "MM/DD/YYYY hh:mm:ss"
                ).format("DD/MM/YYYY");
                return time;
            } else {
                if (orderDataItem.value.toLowerCase() === "true") {
                    return "Ja";
                } else if (orderDataItem.value.toLowerCase() === "false") {
                    return "Nei";
                }
                return orderDataItem.value;
            }
        }

        function stringToBase64(str){
            const blobObj = new Blob([str], {type: 'text/plain'});

            return new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = () => {
                    const dataUrl = reader.result;
                    const base64 = dataUrl.split(',')[1];
                    resolve(base64);
                };
                reader.readAsDataURL(blobObj);
            })
            .catch(() => {
                //btoa is deprecated
                return btoa(unescape(encodeURIComponent(str)));
            });
        }

        function arrayFromString(str, delimeter = ',', shouldTrim = false) {
            if (!str) return [];
            const splitted = str.split(delimeter);
            if (shouldTrim) {
                const trimmed = splitted.map(s => s.trim());
                return trimmed.filter(s => s);
            }
            return splitted;
        }

        function correctDataButtonValueFloatFormat(data) {
            var dataValue = data;
            if (dataValue.indexOf(',') === 0) {
                dataValue = '0' + dataValue;
            }

            if (dataValue.indexOf(',') === dataValue.length - 1) {
                dataValue = dataValue.substring(0, dataValue.length - 1);
            }
            return dataValue;
        }

        function convertBooleanStringToBoolean(value) {
            if (value === 'true' || value === true) {
                return true;
            }
            else if (value === 'false' || value === false) {
                return false;
            } else {
                return undefined;
            }

        }

        function hideModal() {
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
        }
    }
})();
