(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('locationNumPad', locationNumPad);

    locationNumPad.$inject = [];


    function locationNumPad() {
        var directive = {
            controller: ['$rootScope', '$scope', 'locationService', locationNumPad],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/shared/directives/location-num-pad.html'
        };

        return directive;

        var locTimer;

        function locationNumPad($rootScope, $scope, locationService) {
            locationService.getCurrentPosition().then(function (position) {
                var pos = {
                    coords: {
                        accuracy: position.coords.accuracy,
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                        speed: position.coords.speed
                    }
                };
                $scope.fakePosition = {...pos, timestamp: Date.now()};
                $rootScope.fakePosition = {...pos, timestamp: Date.now()};
            });
            document.addEventListener("keydown", keypress_handler, false);

            function keypress_handler(e) {
                if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
                    // 0-9 only
                    switch (e.keyCode) {
                        case 97:
                            {
                                $scope.move('down-left');
                                break;
                            }
                        case 98:
                            {
                                $scope.move('down');
                                break;
                            }
                        case 99:
                            {
                                $scope.move('down-right');
                                break;
                            }
                        case 100:
                            {
                                $scope.move('left');
                                break;
                            }
                        case 102:
                            {
                                $scope.move('right');
                                break;
                            }
                        case 103:
                            {
                                $scope.move('up-left');
                                break;
                            }
                        case 104:
                            {
                                $scope.move('up');
                                break;
                            }
                        case 105:
                            {
                                $scope.move('up-right');
                                break;
                            }
                        default:
                            {

                            }
                    }
                }
            }

            $scope.drive = function () {
                if (locTimer) {
                    clearInterval(locTimer);
                    locTimer = null;
                } else {
                    locTimer = setInterval(() => $scope.move($scope.lastMove), 1000);
                }
            }

            $scope.move = function (direction) {
                var pos = $scope.fakePosition;

                if (!$scope.accuracy) {
                    $scope.accuracy = 10;
                }
                if (!$scope.speed) {
                    $scope.speed = 1;
                }
                if (!$scope.delta) {
                    $scope.delta = 0.0001;
                }
                if (!$scope.delay) {
                    $scope.delay = 0;
                }

                $scope.lastMove = direction;
                pos.coords.accuracy = Number($scope.accuracy);
                pos.coords.speed = $scope.speed == 'undef' ? undefined : Number($scope.speed);
                var delta = Number($scope.delta);

                switch (direction) {
                    case 'up':
                        {
                            pos.coords.latitude += delta;
                            break;
                        }
                    case 'left':
                        {
                            pos.coords.longitude -= delta;
                            break;
                        }
                    case 'up-left':
                        {
                            pos.coords.latitude += delta;
                            pos.coords.longitude -= delta;
                            break;
                        }
                    case 'up-right':
                        {
                            pos.coords.longitude += delta;
                            pos.coords.latitude += delta;
                            break;
                        }
                    case 'right':
                        {
                            pos.coords.longitude += delta;
                            break;
                        }
                    case 'down':
                        {
                            pos.coords.latitude -= delta;
                            break;
                        }
                    case 'down-right':
                        {
                            pos.coords.longitude += delta;
                            pos.coords.latitude -= delta;
                            break;
                        }
                    case 'down-left':
                        {
                            pos.coords.latitude -= delta;
                            pos.coords.longitude -= delta;
                            break;
                        }
                    case 'Trondheim':
                        {
                            pos.coords.latitude = 63.3705;
                            pos.coords.longitude = 10.5331;
                            break;
                        }
                    case 'Stavanger':
                        {
                            pos.coords.latitude = 58.96;
                            pos.coords.longitude = 5.7;
                            break;
                        }
                    default:
                }
                if ($scope.delay && $scope.delay > 0) {
                    setTimeout(() => $rootScope.$broadcast('locationChanged', pos), Number($scope.delay));
                } else {
                    $rootScope.$broadcast('locationChanged', pos);
                }
            }

        }
    }
})();
