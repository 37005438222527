(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('RouteStop', RouteStopFactory);

    RouteStopFactory.$inject = ['$log', 'displayProfileType', 'routeStopConstants', 'translateService']

    function RouteStopFactory($log, displayProfileType, routeStopConstants, translateService) {
        function RouteStop(routeStopData, currentPosition, dataButtons) {
            var self = this;

            try {

                self.updateDistance = function (position) {
                    if (position && self.latitude && self.longitude) {
                        var stopCoords = { latitude: self.latitude, longitude: self.longitude },
                            currentPos = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                        
                        self.distance = geolib.getDistance(currentPos, stopCoords);
                        self.distanceText = self.getDistanceText();
                    }
                };

                self.getDistanceText = function () {
                    if (self.distance || self.distance === 0) {
                        if (self.distance >= 1000) {
                            return (self.distance / 1000).toFixed(2).replace('.', ',') + ' km';
                        } else {
                            return self.distance + ' m';
                        }
                    } else {
                        return 'N/A';
                    }
                };

                self.getDirectionLink = function (currentPosition) {
                    if (currentPosition && self.latitude && self.longitude) {
                        return 'https://www.google.com/maps/dir/?api=1&origin=' +
                            currentPosition.coords.latitude +
                            ',' +
                            currentPosition.coords.longitude +
                            '&destination=' +
                            self.latitude +
                            ',' +
                            self.longitude +
                            '&travelmode=driving';
                    }

                    return '';
                };

                self.hasFillValue = function () {
                    return _.some(self.units, function (unit) { return unit.fill; });
                };

                self.getMaxFillValue = function () {
                    return _.max(_.map(self.units,
                        function (unit) {
                            return _.toNumber(_.replace(unit.fill, '%', '')) || 0;
                        })) ||
                        0;
                };

                self.getDetailDataMessage = function () {
                    return _.find(self.orderData, function (orderDataItem) {
                        return _.includes(orderDataItem.description, 'DetailData_Message');
                    });
                }

                self.getMessageParts = function () {
                    var messageOrderData = self.getDetailDataMessage();

                    return messageOrderData ? messageOrderData.value.split(';') : null;
                };

                self.getMessageNumber = function () {
                    var messageParts = self.getMessageParts();
                    var messageNumber = messageParts && messageParts.length > 1 ? messageParts[1] : null;

                    if (messageNumber && isNaN(messageNumber)) {
                        messageNumber = '-';
                    }

                    return messageNumber;
                };

                self.getExactDateWhenFull = function () {
                    if (self.dateWhenFull && moment(self.dateWhenFull).isValid()) {
                        return moment(self.dateWhenFull).format('DD.MM.YYYY');
                    }

                    return 'N/A';
                }

                self.getDateWhenFull = function () {
                    let dateWhenFullText = self.dateWhenFull;

                    if (dateWhenFullText) {
                        let dateWhenFull = moment(dateWhenFullText);
                        if (dateWhenFull < moment().endOf('day')) {
                            return translateService.translate('routeStopCard.today');
                        }
                        if (dateWhenFull < moment().add(2, 'day').endOf('day')) {
                            return translateService.translate('routeStopCard.oneToThreeDays');
                        }
                        if (dateWhenFull < moment().add(5, 'day').endOf('day')) {
                            return translateService.translate('routeStopCard.fourToSixDays');
                        }
                        if (dateWhenFull < moment().add(12, 'day').endOf('day')) {
                            return translateService.translate('routeStopCard.sevenToFourteenDays');
                        }

                        return translateService.translate('routeStopCard.moreThanFourteenDays');
                    }

                    return 'N/A';
                };

                self.isFillNotAvailable = function (unit) {
                    let detailMessage = self.getDetailDataMessage(),
                        detailMessageValue = detailMessage && detailMessage.value;

                    if (detailMessageValue && (detailMessageValue.includes('damaged') || detailMessageValue.includes('missing'))) {
                        return true;
                    }

                    //Rule: fillValue below 0 is set intentionally to indicate fill is not available, display N/A
                    if (unit.fill && unit.fill.startsWith('-') && unit.fillValue && unit.fillValue < 0) {
                        return true;
                    }

                    return false;
                };

                self.getFill = function (unit) {
                    if (self.isFillNotAvailable(unit)) {
                        return 'N/A';
                    }

                    if (unit.fillText) {
                        return unit.fillText;
                    }

                    return unit.fill;
                };

                self.getExactFill = function (unit) {
                    if (self.isFillNotAvailable(unit)) {
                        return 'N/A';
                    }

                    //Round values over 100 back to 100
                    let fillNumber = parseInt(unit.fill);
                    if (!isNaN(fillNumber)) {
                        return (Math.min(fillNumber, 100)).toString() + '%';
                    }

                    return unit.fill;
                };

                self.getMessageText = function () {
                    var messageParts = self.getMessageParts();
                    return messageParts && messageParts.length ? messageParts[0] : null;
                };

                self.assignLogData = function (currentPosition) {
                    self.addOrUpdateLogData('DegLat', currentPosition.coords.latitude);
                    self.addOrUpdateLogData('DegLon', currentPosition.coords.longitude);
                };

                self.addOrUpdateLogData = function (name, value) {
                    var logDataElement = _.find(self.logData, { 'name': name });

                    if (logDataElement)
                        logDataElement.value = value;
                    else
                        self.logData.push({ 'name': name, 'value': value });
                };

                if (routeStopData) {
                    this.agreementLines = routeStopData.agreementLines;
                    this.comments = routeStopData.comments || [];
                    this.qrCodes = routeStopData.qrCodes || [];
                    this.description = routeStopData.description || '';
                    this.deviations = routeStopData.deviations || [];
                    this.deviationCategories = routeStopData.deviationCategories || [];
                    this.favoritDeviations = routeStopData.favoritDeviations || [];
                    this.deviationPictures = routeStopData.deviationPictures || [];
                    this.displayProfileType = routeStopData.displayProfileType ?? displayProfileType.default;
                    this.distanceText = self.getDistanceText();
                    this.isAttended = routeStopData.isAttended || false;
                    this.grade = routeStopData.grade;
                    this.logData = routeStopData.logData || [];
                    this.logType = routeStopData.logType;
                    this.message = '';
                    this.orderData = routeStopData.orderData || {};
                    this.routeLineId = routeStopData.routeLineId;
                    this.sequence = routeStopData.sequence;
                    this.status = routeStopData.status || 'uncompleted';
                    this.successful = routeStopData.successful || 'unsuccessful';
                    this.system = routeStopData.paSystem;
                    this.units = routeStopData.units;
                    this.wasClose = routeStopData.wasClose || false;
                    this.wasWeightModalClosed = routeStopData.wasWeightModalClosed || false;
                    this.wasteType = routeStopData.pointType;

                    if (this.units && this.units.length > 0) {
                        this.address = routeStopData.units[0].address;
                    }

                    for (var i = 0; i < this.units.length; ++i) {
                        this.units[i].displayText = this.units[i].displayText || this.units[i].containerId;
                        this.units[i].fillText = this.units[i].fillText || '';
                        this.units[i].fillValue = getFillValue(this.units[i].fill);
                        this.units[i].message = this.units[i].message || '';
                        this.units[i].orderDetailText = formatOrderDetailText(this.units[i].orderDetailText);
                        this.units[i].voltText = formatVolt(this.units[i].volt);
                    }

                    this.isAutoComplete = "False";
                    this.latitude = routeStopData.degLat;
                    this.longitude = routeStopData.degLong;

                    this.dateWhenFull = _.min(_.map(this.units, u => u.dateWhenFull));

                    //If current position is provided, calculate the distance
                    if (currentPosition) {
                        self.updateDistance(currentPosition);
                    }

                    if (dataButtons) {
                        this.dataButtons = [];
                        for (var j = 0; j < dataButtons.length; j++) {
                            setDefaultValueNOifNull(dataButtons[j]);
                            if (dataButtonHasNameAndIdentity(dataButtons[j])) {
                                if (!this.dataButtons.includes(dataButtons[j])) {
                                    this.dataButtons.push(angular.copy(dataButtons[j]));
                                }
                            }
                        }
                    }

                    const { types: wasteTypes  } = routeStopConstants.wasteType;

                    if (this.dataButtons?.length && this.wasteType?.toLowerCase() === wasteTypes.sludge) {
                        this.dataButtons[0].value = this.units[0].volume;
                    }
                }
            } catch (error) {
                $log.error("error occured parsing routestops: " + error);
                throw "ParsingError";
            }
        }

        function setDefaultValueNOifNull(dataButton) {
            if (!dataButton.valueNO) {
                dataButton.valueNO = dataButton.valueENG ? dataButton.valueENG : dataButton.parameter;
            }
        }

        function dataButtonHasNameAndIdentity(dataButton) {
            if (!dataButton.valueNO) {
                return false;
            }

            return dataButton.identity !== ''
                && dataButton.valueNO.indexOf('Data ') !== 0
                && dataButton.valueNO.indexOf('-') !== 0
        }

        //Private functions
        function getFillValue(fill) {
            var fillValue = parseFloat(fill ? fill.replace('%', '') : 0);

            fillValue = !isNaN(fillValue) ? fillValue : 0;
            fillValue = fillValue <= 100 ? fillValue : 100;

            return fillValue;
        }

        function formatOrderDetailText(orderDetailText) {
            var formattedOrderDetailText = orderDetailText;

            if (formattedOrderDetailText) {
                formattedOrderDetailText = _.replace(formattedOrderDetailText, 'OrderDetailTypeInfo_#', '');
            }

            return formattedOrderDetailText;
        }

        function formatVolt(volt) {
            if (!volt)
                return '0';

            var formattedVolt = volt;
            if (volt.length > 2 && volt.indexOf(',') === -1) { //correct former bug where voltage comes as string "134" -> 13,4V
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(2);
            } else if (volt.length > 2 && volt.indexOf(',') !== -1) {
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(3, 2);
            } else if (volt.length > 2 && volt.indexOf('.') !== -1) {
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(3, 2);
            }

            if (formattedVolt.length > 0) {
                formattedVolt += 'V';
            }

            return formattedVolt;
        }

        return RouteStop;
    }
})();
